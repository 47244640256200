import { codeSplit } from "bernie-core";
import { FlexComponentMap, ImportReactComponent } from "./typings";

export const flexComponentMap: FlexComponentMap = (new Map() as FlexComponentMap)
  .set("region", {
    aliases: [
      "region_wizard",
      "region_FullWidthWizard",
      "region_FullWidthBreadcrumbs",
      "region_dx-page-header",
      "region_dx-main-content",
      "region_dx-page-content",
      "region_SEO links",
      "region_CenteredDestinationWizard",
      "region_Weekend Getaways",
      "region_HeroEditorial",
      "region_banner_ad_1",
      "region_geo_hotels_1",
      "region_BottomPaddingWizard",
      "region_grid-container",
    ],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region" */ "components/flexComponents/SimpleContainer/SimpleContainer"
        ) as ImportReactComponent
    ),
  })
  .set("region_storefront-wizard", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_storefront-wizard" */ "components/flexComponents/WizardPWARegions/StorefrontWizardRegion/StorefrontWizardRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_storefront-wizard-bex", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_storefront-wizard-bex" */ "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionBEX/StorefrontWizardRegionBEX"
        ) as ImportReactComponent
    ),
  })
  .set("region_storefront-wizard-taap", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_storefront-wizard-taap" */ "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionTAAP/StorefrontWizardRegionTAAP"
        ) as ImportReactComponent
    ),
  })
  .set("region_storefront-wizard-comet", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_storefront-wizard-comet" */ "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionCOMET/StorefrontWizardRegionCOMET"
        ) as ImportReactComponent
    ),
  })
  .set("region_centralized-wizard", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_centralized-wizard" */ "components/flexComponents/WizardPWARegions/CentralizedWizardRegion/CentralizedWizardRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_lazy-load", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_lazy-load" */ "components/flexComponents/LazyLoadRegion/LazyLoadRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_wizard-hotwire", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_wizard-hotwire" */ "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionHotwire"
        ) as ImportReactComponent
    ),
  })
  .set("region_cta-carousel", {
    aliases: ["region_lob-carousel", "region_grid-merch-container"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_cta-carousel" */ "components/flexComponents/CTACarouselRegion/CTACarouselRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_mobile-confidence-messages-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_mobile-confidence-messages-carousel" */ "components/flexComponents/MobileConfidenceMessagesCarouselRegion/MobileConfidenceMessagesCarouselRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_wizard-background-image-or-color", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_wizard-background-image-or-color" */ "components/flexComponents/WizardBackgroundImageOrColorRegion/WizardBackgroundImageOrColorRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_affinity-container", {
    aliases: ["region_affinity-full-width", "region_affinity-image"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_affinity-container" */ "src/components/flexComponents/AffinityRegion/AffinityRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_call-to-action-group", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_call-to-action-group" */ "src/components/flexComponents/CallToActionGroupRegion/CallToActionGroupRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_destination-sidebar", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_destination-sidebar" */ "src/components/flexComponents/DestinationSidebarRegion/DestinationSidebarRegion"
        ) as ImportReactComponent
    ),
  })
  .set("breadcrumbs", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-breadcrumbs" */ "components/flexComponents/Breadcrumbs/Breadcrumbs"
        ) as ImportReactComponent
    ),
  })
  .set("breadcrumbs-v2", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-breadcrumbs-v2" */ "src/components/flexComponents/Breadcrumbs/shared-ui/Breadcrumbs"
        ) as ImportReactComponent
    ),
  })
  .set("call-to-action", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-call-to-action" */ "components/flexComponents/CallToAction/CallToAction"
        ) as ImportReactComponent
    ),
  })
  .set("check-prices", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-check-prices" */ "components/flexComponents/CheckPrices/CheckPrices"
        ) as ImportReactComponent
    ),
  })
  .set("cross-lob-links", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cross-lob-links" */ "components/flexComponents/CrossLOBLinks/CrossLOBLinks"
        ) as ImportReactComponent
    ),
  })
  .set("destination-attractions", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-attractions" */ "components/flexComponents/DestinationAttractions/DestinationAttractions"
        ) as ImportReactComponent
    ),
  })
  .set("destination-description", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-description" */ "components/flexComponents/DestinationDescription/views/DefaultDestinationDescription"
        ) as ImportReactComponent
    ),
  })
  .set("destination-description_cgs", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-description_cgs" */ "components/flexComponents/DestinationDescription/views/HtmlDestinationDescription"
        ) as ImportReactComponent
    ),
  })
  .set("destination-discovery-map", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-discovery-map" */ "components/flexComponents/DestinationDiscoveryMap/DestinationDiscoveryMap"
        ) as ImportReactComponent
    ),
  })
  .set("destination-hero", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-hero" */ "components/flexComponents/DestinationHero/DestinationHero"
        ) as ImportReactComponent
    ),
  })
  .set("region_destination-hero-card", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_destination-hero-card" */ "components/flexComponents/DestinationHeroRegion/DestinationHeroRegion"
        ) as ImportReactComponent
    ),
  })
  .set("destination-neighborhoods", {
    aliases: ["te-neighborhood"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-neighborhoods" */ "components/flexComponents/DestinationNeighborhoods/DestinationNeighborhoods"
        ) as ImportReactComponent
    ),
  })
  .set("destination-suggestions", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-suggestions" */ "components/flexComponents/DestinationSuggestions/DestinationSuggestions"
        ) as ImportReactComponent
    ),
  })
  .set("destination-user-reviews", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-user-reviews" */ "components/flexComponents/DestinationUserReviews/DestinationUserReviews"
        ) as ImportReactComponent
    ),
  })
  .set("editorial", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-editorial" */ "components/flexComponents/Editorial/Content"
        ) as ImportReactComponent
    ),
  })
  .set("frequently-asked-questions", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-frequently-asked-questions" */ "components/flexComponents/Faq/Faq"
        ) as ImportReactComponent
    ),
  })
  .set("frequently-asked-questions-v2", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-frequently-asked-questions-v2" */ "components/flexComponents/Faq/shared-ui/Faq"
        ) as ImportReactComponent
    ),
  })
  .set("hotel-recent-reviews", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotel-recent-reviews" */ "components/flexComponents/HotelRecentReviews/HotelRecentReviews"
        ) as ImportReactComponent
    ),
  })
  .set("hotel-recent-reviews_view", {
    aliases: ["hotel-recent-reviews_thumbnail-image-left-card", "hotel-recent-reviews_header-image-card"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotel-recent-reviews_view" */ "components/flexComponents/HotelRecentReviews/views/HotelRecentReviewsView"
        ) as ImportReactComponent
    ),
  })
  .set("hotels", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels" */ "components/flexComponents/Hotels/Hotels"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_carousel" */ "components/flexComponents/Hotels/views/ViewCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_travellers-loved", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_travellers-loved" */ "components/flexComponents/Hotels/views/TravelersLovedHotels"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_summarized-hotels", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_summarized-hotels" */ "src/components/flexComponents/Hotels/views/SummarizedHotels"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_placeholder-only", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_placeholder-only" */ "src/components/flexComponents/Hotels/views/HotelsPlaceHolderOnly"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_summarized-hotels-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_summarized-hotels-carousel" */ "src/components/flexComponents/Hotels/views/SummarizedHotelsCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_summarized-hotels-carousel-top-image", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_summarized-hotels-carousel-top-image" */ "src/components/flexComponents/Hotels/views/SummarizedHotelsCarouselTopImage"
        ) as ImportReactComponent
    ),
  })
  .set("jumplinks", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-jumplinks" */ "components/flexComponents/Jumplinks/Jumplinks"
        ) as ImportReactComponent
    ),
  })
  .set("links", {
    component: codeSplit(
      () =>
        import(/* webpackChunkName: "blossom-links" */ "components/flexComponents/Links/Links") as ImportReactComponent
    ),
  })
  .set("links_card-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-links_card-carousel" */ "components/flexComponents/Links/views/CardsCarouselLinksView"
        ) as ImportReactComponent
    ),
  })
  .set("maps", {
    aliases: ["maps_dynamic"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-maps" */ "components/flexComponents/Maps/MapsViews"
        ) as ImportReactComponent
    ),
  })
  .set("maps_button", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-maps_button" */ "components/flexComponents/Maps/views/MapButton"
        ) as ImportReactComponent
    ),
  })
  .set("page-heading", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-page-heading" */ "components/flexComponents/PageHeading/PageHeading"
        ) as ImportReactComponent
    ),
  })
  .set("page-heading_wizard", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-page-heading_wizard" */ "components/flexComponents/PageHeading/view/PageHeadingWizard"
        ) as ImportReactComponent
    ),
  })
  .set("page-heading_property-filter-experience", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-page-heading_property-filter-experience" */ "components/flexComponents/PageHeading/view/PageHeadingFilterExperience"
        ) as ImportReactComponent
    ),
  })
  .set("recently-booked-hotels", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recently-booked-hotels" */ "components/flexComponents/RecentlyBookedHotels/RecentlyBookedHotels"
        ) as ImportReactComponent
    ),
  })
  .set("recently-booked-hotels_view", {
    aliases: [
      "recently-booked-hotels_thumbnail-image-left-card-carousel",
      "recently-booked-hotels_full-bleed-image-card-carousel",
    ],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recently-booked-hotels_view" */ "components/flexComponents/RecentlyBookedHotels/views/RecentlyBookedHotelsView"
        ) as ImportReactComponent
    ),
  })
  .set("similar-destiations", {
    aliases: ["te-similar-destination"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-similar-destiations" */ "components/flexComponents/SimilarDestinations/SimilarDestinations"
        ) as ImportReactComponent
    ),
  })
  .set("single-card-marquee", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-single-card-marquee" */ "components/flexComponents/SingleCardMarquee/SingleCardMarquee"
        ) as ImportReactComponent
    ),
  })
  .set("top-cities", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-top-cities" */ "components/flexComponents/TopCities/TopCities"
        ) as ImportReactComponent
    ),
  })
  .set("te-neighborhood_neighborhood_with_dx_hsr", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-te-neighborhood_neighborhood_with_dx_hsr" */ "components/flexComponents/DestinationNeighborhoods/views/TopNeighborhoods"
        ) as ImportReactComponent
    ),
  })
  .set("te-neighborhood_carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-te-neighborhood_carousel" */ "src/components/flexComponents/DestinationNeighborhoods/views/TopNeighborhoodsCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("video-cards-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-video-cards-carousel" */ "src/components/flexComponents/VideoCardsCarousel/VideoCardsCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("visitor-blogs", {
    aliases: ["te-article"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-visitor-blogs" */ "components/flexComponents/VisitorBlogs/VisitorBlogs"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-hotel-pwa", {
    aliases: ["wizard"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-hotel-pwa" */ "components/flexComponents/WizardHotelPWA/WizardHotelPWAViews"
        ) as ImportReactComponent
    ),
  })
  .set("ad", {
    component: codeSplit(
      () => import(/* webpackChunkName: "blossom-ad" */ "components/flexComponents/Ad/Ad") as ImportReactComponent
    ),
  })
  .set("tab-group", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-tab-group" */ "components/flexComponents/TabGroup/TabGroup"
        ) as ImportReactComponent
    ),
  })
  .set("tab", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-tab" */ "src/components/flexComponents/TabContents/TabContents"
        ) as ImportReactComponent
    ),
  })
  .set("link-juice", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-link-juice" */ "components/flexComponents/LinkJuice/LinkJuice"
        ) as ImportReactComponent
    ),
  })
  .set("link-list", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-link-list" */ "src/components/flexComponents/LinkList/LinkList"
        ) as ImportReactComponent
    ),
  })
  .set("destination-where-to-stay", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-where-to-stay" */ "components/flexComponents/DestinationWhereToStay/DestinationWhereToStay"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-car-pwa", {
    aliases: ["wizard-gt-pwa"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-car-pwa" */ "components/flexComponents/WizardCarPWA/WizardCarPWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-lx-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-lx-pwa" */ "components/flexComponents/WizardLXPWA/WizardLXPWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-thirdPartyPackage-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-thirdPartyPackage-pwa" */ "components/flexComponents/Wizard3ppPWA/Wizard3ppPWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-externalLinkTab-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-externalLinkTab-pwa" */ "components/flexComponents/WizardExternalLinkTabPWA/WizardExternalLinkTabPWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-cruise-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-cruise-pwa" */ "components/flexComponents/WizardCruisePWA/WizardCruisePWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-package-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-package-pwa" */ "components/flexComponents/WizardPackagePWA/WizardPackagePWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-flight-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-flight-pwa" */ "components/flexComponents/WizardFlightPWA/WizardFlightPWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-vr-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-vr-pwa" */ "src/components/flexComponents/WizardVRPWA/WizardVRPWA"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-hotel-pwa-v2", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-hotel-pwa-v2" */ "components/flexComponents/WizardHotelPWAv2/WizardHotelPWAv2"
        ) as ImportReactComponent
    ),
  })
  .set("destination-video", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-video" */ "components/flexComponents/DestinationVideo/DestinationVideo"
        ) as ImportReactComponent
    ),
  })
  .set("recently-viewed-hotels", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recently-viewed-hotels" */ "components/flexComponents/RecentlyViewedHotels/RecentlyViewedHotels"
        ) as ImportReactComponent
    ),
  })
  .set("destination-cross-link", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-cross-link" */ "components/flexComponents/DestinationCrossLink/DestinationCrossLink"
        ) as ImportReactComponent
    ),
  })
  .set("merch-campaigns", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-merch-campaigns" */ "src/components/flexComponents/MerchCampaign/MerchCampaign"
        ) as ImportReactComponent
    ),
  })
  .set("recently-viewed", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recently-viewed" */ "components/flexComponents/RecentlyViewedWrapper/RecentlyViewedWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("property-filters", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-filters" */ "components/flexComponents/PropertyFilters/PropertyFilters"
        ) as ImportReactComponent
    ),
  })
  .set("property-filters_pills", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-filters_pills" */ "components/flexComponents/PropertyFilters/views/PropertyFiltersPills"
        ) as ImportReactComponent
    ),
  })
  .set("region_property-filters-interactive", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_property-filters-interactive" */ "components/flexComponents/PropertyFiltersInteractive/PropertyFiltersInteractive"
        ) as ImportReactComponent
    ),
  })
  .set("property-filters_sort", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-filters_sort" */ "components/flexComponents/PropertyFilters/views/PropertyFiltersSort"
        ) as ImportReactComponent
    ),
  })
  .set("packaging", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-packaging" */ "components/flexComponents/Packages/Packages"
        ) as ImportReactComponent
    ),
  })
  .set("property-gallery", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-gallery" */ "components/flexComponents/PropertyGallery/PropertyGallery"
        ) as ImportReactComponent
    ),
  })
  .set("property-summary", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-summary" */ "components/flexComponents/PropertySummary/PropertySummary"
        ) as ImportReactComponent
    ),
  })
  .set("property-summary-header", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-summary-header" */ "components/flexComponents/PropertySummaryHeader/PropertySummaryHeader"
        ) as ImportReactComponent
    ),
  })
  .set("property-summary_location-highlights", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-summary_location-highlights" */ "components/flexComponents/PropertySummary/views/PropertySummaryLocationHighlights"
        ) as ImportReactComponent
    ),
  })
  .set("property-summary_single-offer", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-summary_single-offer" */ "components/flexComponents/PropertySummary/views/PropertySummarySingleOffer"
        ) as ImportReactComponent
    ),
  })
  .set("property-summary_offer-price", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-summary_offer-price" */ "components/flexComponents/PropertySummary/views/PropertySummaryOfferPrice"
        ) as ImportReactComponent
    ),
  })
  .set("property-summary_dateless-pdp", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-summary_dateless-pdp" */ "components/flexComponents/PropertySummary/views/PropertySummaryDatelessPDP"
        ) as ImportReactComponent
    ),
  })
  .set("property-whats-around", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-whats-around" */ "components/flexComponents/PropertyWhatsAround/PropertyWhatsAround"
        ) as ImportReactComponent
    ),
  })
  .set("property-whats-around_lazyload", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-whats-around_lazyload" */ "components/flexComponents/PropertyWhatsAround/view/PropertyWhatsAroundLazyLoad"
        ) as ImportReactComponent
    ),
  })
  .set("property-filter-pills", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-filter-pills" */ "components/flexComponents/PropertyPillFilters/PropertyPillFilters"
        ) as ImportReactComponent
    ),
  })
  .set("property-amenity-description", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-amenity-description" */ "components/flexComponents/PropertyAmenityDescription/PropertyAmenityDescription"
        ) as ImportReactComponent
    ),
  })
  .set("property-amenity-description_lazyload", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-amenity-description_lazyload" */ "components/flexComponents/PropertyAmenityDescription/view/PropertyAmenityDescriptionLazyLoad"
        ) as ImportReactComponent
    ),
  })
  .set("property-faq", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-faq" */ "components/flexComponents/PropertyFaq/PropertyFaq"
        ) as ImportReactComponent
    ),
  })
  .set("property-navigation-bar", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-navigation-bar" */ "components/flexComponents/PropertyNavigationBar/PropertyNavigationBar"
        ) as ImportReactComponent
    ),
  })
  .set("property-policies", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-policies" */ "components/flexComponents/PropertyPolicies/PropertyPolicies"
        ) as ImportReactComponent
    ),
  })
  .set("property-policies_lazyload", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-policies_lazyload" */ "components/flexComponents/PropertyPolicies/view/PropertyPoliciesLazyLoad"
        ) as ImportReactComponent
    ),
  })
  .set("property-about-this", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-about-this" */ "components/flexComponents/PropertyAboutThis/PropertyAboutThis"
        ) as ImportReactComponent
    ),
  })
  .set("property-important-info", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-important-info" */ "components/flexComponents/PropertyImportantInfo/PropertyImportantInfo"
        ) as ImportReactComponent
    ),
  })
  .set("property-special-features", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-special-features" */ "components/flexComponents/PropertySpecialFeatures/PropertySpecialFeatures"
        ) as ImportReactComponent
    ),
  })
  .set("property-offers", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-offers" */ "components/flexComponents/PropertyOffers/PropertyOffers"
        ) as ImportReactComponent
    ),
  })
  .set("property-single-offer", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-single-offer" */ "src/components/flexComponents/PropertyOffers/views/PropertySingleOffer"
        ) as ImportReactComponent
    ),
  })
  .set("property-offers_lazyload", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-offers_lazyload" */ "src/components/flexComponents/PropertyOffers/views/PropertyOffersLazy"
        ) as ImportReactComponent
    ),
  })
  .set("property-reviews", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-reviews" */ "components/flexComponents/PropertyReviewsWrapper/PropertyReviewsWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("property-reviews-headline", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-reviews-headline" */ "components/flexComponents/PropertyReviewsHeadline/PropertyReviewsHeadlineWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("property-hero-image", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-hero-image" */ "components/flexComponents/PropertyHeroImage/PropertyHeroImageWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("destination-nearest", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-nearest" */ "components/flexComponents/DestinationNearest/DestinationNearest"
        ) as ImportReactComponent
    ),
  })
  .set("deals", {
    component: codeSplit(
      () =>
        import(/* webpackChunkName: "blossom-deals" */ "components/flexComponents/Deals/Deals") as ImportReactComponent
    ),
  })
  .set("separator", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-separator" */ "components/flexComponents/Separator/Separator"
        ) as ImportReactComponent
    ),
  })
  .set("region_native-ad-container", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_native-ad-container" */ "components/flexComponents/NativeAdContainerRegion/NativeAdContainerRegion"
        ) as ImportReactComponent
    ),
  })
  .set("loyalty-acquisition-banner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-loyalty-acquisition-banner" */ "components/flexComponents/LoyaltyAcquisitionBanner/LoyaltyAcquisitionBanner"
        ) as ImportReactComponent
    ),
  })
  .set("hotels_view", {
    aliases: ["hotels_image-top", "hotels_image-left-small", "hotels_image-left-large", "hotels_image-half"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotels_view" */ "components/flexComponents/Hotels/views/HotelsViews/HotelsViews"
        ) as ImportReactComponent
    ),
  })
  .set("global-sitemap", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-global-sitemap" */ "components/flexComponents/GlobalSiteMaps/GlobalSiteMaps"
        ) as ImportReactComponent
    ),
  })
  .set("editorial_banner-v2", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-editorial_banner-v2" */ "components/flexComponents/EditorialBanner/EditorialBanner"
        ) as ImportReactComponent
    ),
  })
  .set("star-rating-links", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-star-rating-links" */ "components/flexComponents/StarRatingLinks/StarRatingLinks"
        ) as ImportReactComponent
    ),
  })
  .set("table", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-table" */ "components/flexComponents/TableFlexModule/TableFlexModule"
        ) as ImportReactComponent
    ),
  })
  .set("hotwire-deals", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotwire-deals" */
          "components/flexComponents/HotwireDeals/HotwireDeals"
        ) as ImportReactComponent
    ),
  })
  .set("region_native-tile-showcase", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_native-tile-showcase" */ "components/flexComponents/NativeTileShowcaseRegion/NativeTileShowcaseRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_nts-flex-item", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_nts-flex-item" */ "components/flexComponents/NativeTileShowcaseItemRegion/NativeTileShowcaseItemRegion"
        ) as ImportReactComponent
    ),
  })
  .set("region_biased-wizard", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_biased-wizard" */ "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionMeso/StorefrontWizardRegionMeso"
        ) as ImportReactComponent
    ),
  })
  .set("cookie-consent-toggle", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cookie-consent-toggle" */ "components/flexComponents/CookieConsentToggle/CookieConsentToggle"
        ) as ImportReactComponent
    ),
  })
  .set("airport-address", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-airport-address" */ "components/flexComponents/AirportAddress/AirportAddress"
        ) as ImportReactComponent
    ),
  })
  .set("mad-marquee", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-mad-marquee" */ "components/flexComponents/MadMarquee/MadMarquee"
        ) as ImportReactComponent
    ),
  })
  .set("travel-advisor", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-travel-advisor" */ "components/flexComponents/TravelAdvisor/TravelAdvisor"
        ) as ImportReactComponent
    ),
  })
  .set("region_auth-container", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_auth-container" */ "components/flexComponents/AuthContainer/AuthContainer"
        ) as ImportReactComponent
    ),
  })
  .set("flights-structured-table", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-flights-structured-table" */ "components/flexComponents/FlightsStructuredTable/FlightsStructuredTable"
        ) as ImportReactComponent
    ),
  })
  .set("coupons", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-coupons" */ "src/components/flexComponents/Coupons/Coupons"
        ) as ImportReactComponent
    ),
  })
  .set("region_meso-collapsible-group", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_meso-collapsible-group" */ "src/components/flexComponents/MesoCollapsibleGroupRegion/MesoCollapsibleGroupRegion"
        ) as ImportReactComponent
    ),
  })
  .set("hotwire-marketing-banner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotwire-marketing-banner" */ "src/components/flexComponents/HotwireMarketingBanner/HotwireMarketingBanner"
        ) as ImportReactComponent
    ),
  })
  .set("nearby-features", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-nearby-features" */ "components/flexComponents/NearbyFeatures/NearbyFeatures"
        ) as ImportReactComponent
    ),
  })
  .set("flights", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-flights" */ "components/flexComponents/Flights/Flights"
        ) as ImportReactComponent
    ),
  })
  .set("flights-review", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-flights-review" */ "components/flexComponents/FlightsReview/FlightsReview"
        ) as ImportReactComponent
    ),
  })
  .set("price-insights-tracking-card", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-price-insights-tracking-card" */ "src/components/flexComponents/PriceInsightsTrackingCard/PriceInsightsTrackingCard"
        ) as ImportReactComponent
    ),
  })
  .set("cars", {
    component: codeSplit(
      () => import(/* webpackChunkName: "blossom-cars" */ "components/flexComponents/Cars/Cars") as ImportReactComponent
    ),
  })
  .set("property-search-link", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-search-link" */ "components/flexComponents/PropertySearchLink/PropertySearchLink"
        ) as ImportReactComponent
    ),
  })
  .set("property-space-details", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-space-details" */ "components/flexComponents/PropertySpaceDetails/PropertySpaceDetails"
        ) as ImportReactComponent
    ),
  })
  .set("travel-advisory", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-travel-advisory" */ "components/flexComponents/TravelAdvisory/TravelAdvisory"
        ) as ImportReactComponent
    ),
  })
  .set("hotwire-affiliate-toast", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hotwire-affiliate-toast" */ "components/flexComponents/HotwireAffiliateToast/HotwireAffiliateToast"
        ) as ImportReactComponent
    ),
  })
  .set("price-trend-graph", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-price-trend-graph" */ "components/flexComponents/PriceTrendGraph/PriceTrendGraph"
        ) as ImportReactComponent
    ),
  })
  .set("category-activities", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-category-activities" */ "components/flexComponents/CategoryActivities/CategoryActivities"
        ) as ImportReactComponent
    ),
  })
  .set("region_toggle", {
    aliases: ["region_expand-collapse-subregions"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_toggle" */ "components/flexComponents/ToggleContainer/ToggleContainer"
        ) as ImportReactComponent
    ),
  })
  .set("region_responsive-toggle", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_responsive-toggle" */ "components/flexComponents/ResponsiveToggleContainer/ResponsiveToggleContainer"
        ) as ImportReactComponent
    ),
  })
  .set("activities-by-category", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-activities-by-category" */ "components/flexComponents/ActivitiesByCategory/ActivitiesByCategory"
        ) as ImportReactComponent
    ),
  })
  .set("meso-ad-configuration-override", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-meso-ad-configuration-override" */ "components/flexComponents/MesoAdConfigurationOverride/MesoAdConfigurationOverride"
        ) as ImportReactComponent
    ),
  })
  .set("activities-by-point-of-interest", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-activities-by-point-of-interest" */ "components/flexComponents/ActivitiesByPointOfInterest/ActivitiesByPointOfInterest"
        ) as ImportReactComponent
    ),
  })
  .set("customer-notifications", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-customer-notifications" */ "components/flexComponents/CustomerNotificationsWrapper/CustomerNotificationsWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("flights-schedule", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-flights-schedule" */ "components/flexComponents/FlightsSchedule/FlightsSchedule"
        ) as ImportReactComponent
    ),
  })
  .set("flights-flexible-search", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-flights-flexible-search" */ "src/components/flexComponents/FlightsFlexibleSearch/FlightsFlexibleSearch"
        ) as ImportReactComponent
    ),
  })
  .set("hw-maps", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-hw-maps" */ "components/flexComponents/HotwireMaps/HotwireMaps"
        ) as ImportReactComponent
    ),
  })
  .set("mock-component", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-mock-component" */ "components/flexComponents/MockComponent/MockComponent"
        ) as ImportReactComponent
    ),
  })
  .set("info-cards", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-info-cards" */ "components/flexComponents/InfoCards/InfoCards"
        ) as ImportReactComponent
    ),
  })
  .set("cr-interlinking", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cr-interlinking" */ "components/flexComponents/Interlinking/Interlinking"
        ) as ImportReactComponent
    ),
  })
  .set("region_meso-ad-group", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_meso-ad-group" */ "src/components/flexComponents/MesoAdGroupRegion/MesoAdGroupRegion"
        ) as ImportReactComponent
    ),
  })
  .set("editorial_banner-hero", {
    aliases: ["editorial_banner-hero-v2"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-editorial_banner-hero" */ "components/flexComponents/EditorialHeroBanner/EditorialHeroBanner"
        ) as ImportReactComponent
    ),
  })
  .set("HeroBanner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-HeroBanner" */ "components/flexComponents/EditorialHeroBanner/EditorialHeroBannerWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("region_spacing-container", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_spacing-container" */ "components/flexComponents/SpacingContainer/SpacingContainer"
        ) as ImportReactComponent
    ),
  })
  .set("title-container", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-title-container" */ "components/flexComponents/TitleContainer/TitleContainer"
        ) as ImportReactComponent
    ),
  })
  .set("region_view-port-container", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_view-port-container" */ "components/flexComponents/ViewPortContainer/ViewPortContainer"
        ) as any
    ),
  })
  .set("cruise-messaging-card", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cruise-messaging-card" */ "components/flexComponents/CruiseMessagingCard/CruiseMessagingCard"
        ) as ImportReactComponent
    ),
  })
  .set("rewards-banner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-rewards-banner" */ "components/flexComponents/RewardsBanner/RewardsBanner"
        ) as ImportReactComponent
    ),
  })
  .set("dated-searches", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-dated-searches" */ "components/flexComponents/DatedSearches/DatedSearches"
        ) as ImportReactComponent
    ),
  })
  .set("lodging-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-lodging-search-form" */ "components/flexComponents/LodgingSearchForm/LodgingSearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("cruise-sailing-search-button", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cruise-sailing-search-button" */ "components/flexComponents/CruiseSailingSearchButton/CruiseSailingSearchButton"
        ) as ImportReactComponent
    ),
  })
  .set("region_full-background-container", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_full-background-container" */ "components/flexComponents/FullBackgroundRegion/FullBackgroundRegion"
        ) as ImportReactComponent
    ),
  })
  .set("wizard-enforce-login-pwa", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-wizard-enforce-login-pwa" */ "components/flexComponents/EnforceUserSignIn/EnforceUserSignIn"
        ) as ImportReactComponent
    ),
  })
  .set("cruise-product-listings", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cruise-product-listings" */ "components/flexComponents/CruiseProductListings/CruiseProductListings"
        ) as ImportReactComponent
    ),
  })
  .set("property-whats-around-pois-view", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-whats-around-pois-view" */ "components/flexComponents/PropertyWhatsAroundPoisView/PropertyWhatsAroundPoisView"
        ) as ImportReactComponent
    ),
  })
  .set("paperform", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-paperform" */ "components/flexComponents/Paperform/Paperform"
        ) as ImportReactComponent
    ),
  })
  .set("incentives-collection", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-incentives-collection" */ "components/flexComponents/IncentivesCollection/IncentivesCollection"
        ) as ImportReactComponent
    ),
  })
  .set("property-relevant-amenities", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-relevant-amenities" */ "components/flexComponents/PropertyRelevantAmenities/PropertyRelevantAmenities"
        ) as ImportReactComponent
    ),
  })
  .set("lodging-search-hero", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-lodging-search-hero" */ "src/components/flexComponents/LodgingSearchHero/LodgingSearchHero"
        ) as ImportReactComponent
    ),
  })
  .set("vip-messaging", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-vip-messaging" */ "components/flexComponents/VipMessagingCard/VipMessagingCard"
        ) as ImportReactComponent
    ),
  })
  .set("property-host-profile", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-host-profile" */ "components/flexComponents/PropertyHostProfile/PropertyHostProfile"
        ) as ImportReactComponent
    ),
  })
  .set("property-host-profile_lazyload", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-host-profile_lazyload" */ "components/flexComponents/PropertyHostProfile/view/PropertyHostProfileLazyLoad"
        ) as ImportReactComponent
    ),
  })
  .set("flight-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-flight-search-form" */ "components/flexComponents/FlightSearchForm/FlightSearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("cruise-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-cruise-search-form" */ "components/flexComponents/CruiseSearchForm/CruiseSearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("legal-document", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-legal-document" */ "components/flexComponents/LegalDocument/LegalDocument"
        ) as ImportReactComponent
    ),
  })
  .set("partner-gallery", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-partner-gallery" */ "components/flexComponents/PartnerGallery/PartnerGallery"
        ) as ImportReactComponent
    ),
  })
  .set("activity-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-activity-search-form" */ "components/flexComponents/ActivitySearchForm/ActivitySearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("destination-recommendation", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-recommendation" */ "src/components/flexComponents/DestinationRecommendation/DestinationRecommendation"
        ) as ImportReactComponent
    ),
  })
  .set("search-form-with-heading", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-search-form-with-heading" */ "components/flexComponents/SearchFormWithHeading/SearchFormWithHeading"
        ) as ImportReactComponent
    ),
  })
  .set("plan-your-trip", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-plan-your-trip" */ "components/flexComponents/PlanYourTrip/PlanYourTrip"
        ) as ImportReactComponent
    ),
  })
  .set("notification-center", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-notification-center" */ "components/flexComponents/NotificationCenter/NotificationCenter"
        ) as ImportReactComponent
    ),
  })
  .set("packages-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-packages-search-form" */ "components/flexComponents/PackagesSearchForm/PackagesSearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("personalized-offers-recommendation", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-personalized-offers-recommendation" */ "components/flexComponents/OffersRecommendation/OffersRecommendation"
        ) as ImportReactComponent
    ),
  })
  .set("property-content-reporting", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-content-reporting" */ "components/flexComponents/PropertyContentReporting/PropertyContentReporting"
        ) as ImportReactComponent
    ),
  })
  .set("incentives-collection-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-incentives-collection-carousel" */ "components/flexComponents/IncentivesCollectionCarousel/IncentivesCollectionCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("travel-agent-authentication-options", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-travel-agent-authentication-options" */ "components/flexComponents/TravelAgentAuthenticationOptions/TravelAgentAuthenticationOptions"
        ) as ImportReactComponent
    ),
  })
  .set("featured-locations", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-featured-locations" */ "components/flexComponents/FeaturedLocations/FeaturedLocations"
        ) as ImportReactComponent
    ),
  })
  .set("recently-viewed-properties", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recently-viewed-properties" */ "components/flexComponents/RecentlyViewedProperties/RecentlyViewedProperties"
        ) as ImportReactComponent
    ),
  })
  .set("destination-properties-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-properties-carousel" */ "components/flexComponents/DestinationPropertiesCarousel/DestinationPropertiesCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("collection-tiles", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-tiles" */ "components/flexComponents/CollectionTiles/CollectionTiles"
        ) as ImportReactComponent
    ),
  })
  .set("collection-lodging-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-lodging-carousel" */ "components/flexComponents/CollectionLodgingCarousel/CollectionLodgingCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("collection-lodging-grid", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-lodging-grid" */ "components/flexComponents/CollectionLodgingGrid/CollectionLodgingGrid"
        ) as ImportReactComponent
    ),
  })
  .set("collection-flights-grid", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-flights-grid" */ "components/flexComponents/CollectionFlightsGrid/CollectionFlightsGrid"
        ) as ImportReactComponent
    ),
  })
  .set("collection-flights-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-flights-carousel" */ "components/flexComponents/CollectionFlightsCarousel/CollectionFlightsCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("collection-packages-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-packages-carousel" */ "components/flexComponents/CollectionPackagesCarousel/CollectionPackagesCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("collection-packages-grid", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-collection-packages-grid" */ "components/flexComponents/CollectionPackagesGrid/CollectionPackagesGrid"
        ) as ImportReactComponent
    ),
  })
  .set("one-key-banner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-one-key-banner" */ "components/flexComponents/OneKeyBanner/OneKeyBanner"
        ) as ImportReactComponent
    ),
  })
  .set("one-key-onboarding", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-one-key-onboarding" */ "components/flexComponents/OneKeyOnboarding/OneKeyOnboarding"
        ) as ImportReactComponent
    ),
  })
  .set("multi-product-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-multi-product-search-form" */ "components/flexComponents/MultiProductSearchForm/MultiProductSearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("user-geo-location", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-user-geo-location" */ "components/flexComponents/UserGeoLocation/UserGeoLocation"
        ) as ImportReactComponent
    ),
  })
  .set("car-search-form", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-car-search-form" */ "components/flexComponents/CarSearchForm/CarSearchForm"
        ) as ImportReactComponent
    ),
  })
  .set("links-juice", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-links-juice" */ "components/flexComponents/LinksJuice/LinksJuice"
        ) as ImportReactComponent
    ),
  })
  .set("geo-link-piles", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-geo-link-piles" */ "components/flexComponents/GeoLinkPiles/GeoLinkPiles"
        ) as ImportReactComponent
    ),
  })
  .set("SearchFormWithBackgroundImage", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-SearchFormWithBackgroundImage" */ "components/flexComponents/SearchFormWithBackground/SearchFormWithBackgroundImageWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("recent-activities-module", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recent-activities-module" */ "components/flexComponents/RecentActivitiesModule/RecentActivitiesModule"
        ) as ImportReactComponent
    ),
  })
  .set("recent-searches", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recent-searches" */ "components/flexComponents/RecentSearches/RecentSearches"
        ) as ImportReactComponent
    ),
  })
  .set("value-proposition-banner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-value-proposition-banner" */ "components/flexComponents/ValuePropositionBanner/ValuePropositionBanner"
        ) as ImportReactComponent
    ),
  })
  .set("retail-recommendation-module", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-retail-recommendation-module" */ "components/flexComponents/RecommendationModule/RecommendationModule"
        ) as ImportReactComponent
    ),
  })
  .set("pre-bundled-curated-card-entry", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-pre-bundled-curated-card-entry" */ "components/flexComponents/PreBundledCuratedCardEntry/PreBundledCuratedCardEntry"
        ) as ImportReactComponent
    ),
  })
  .set("affinity-link-piles", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-affinity-link-piles" */ "components/flexComponents/AffinityLinkPiles/AffinityLinkPiles"
        ) as ImportReactComponent
    ),
  })
  .set("mab-wrapper-component", {
    aliases: ["internal-mab-component-selection", "internal-mab-component-ranking"],
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-mab-wrapper-component" */ "components/flexComponents/MabWrapperComponent/MabWrapperComponent"
        ) as ImportReactComponent
    ),
  })
  .set("experience-card", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-experience-card" */ "components/flexComponents/ExperienceCard/ExperienceCard"
        ) as ImportReactComponent
    ),
  })
  .set("layout", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-layout" */ "src/components/flexComponents/ExperienceManager/Layout/Layout"
        ) as ImportReactComponent
    ),
  })
  .set("section", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-section" */ "src/components/flexComponents/ExperienceManager/Section/Section"
        ) as ImportReactComponent
    ),
  })
  .set("section-grid", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-section-grid" */ "src/components/flexComponents/ExperienceManager/SectionGrid/SectionGrid"
        ) as ImportReactComponent
    ),
  })
  .set("recommendations-overlay", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-recommendations-overlay" */ "components/flexComponents/RecommendationsOverlay/RecommendationsOverlay"
        ) as ImportReactComponent
    ),
  })
  .set("property-book-bar", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-book-bar" */ "components/flexComponents/PropertyBookBar/PropertyBookBar"
        ) as ImportReactComponent
    ),
  })
  .set("landing-search-hero", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-landing-search-hero" */ "components/flexComponents/LandingSearchHero/LandingSearchHero"
        ) as ImportReactComponent
    ),
  })
  .set("consent-management-legal-landing", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-consent-management-legal-landing" */ "components/flexComponents/ConsentManagementLegalLanding/ConsentManagementLegalLanding"
        ) as ImportReactComponent
    ),
  })
  .set("trips-album-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-trips-album-carousel" */ "components/flexComponents/TripsAlbumCarousel/TripsAlbumCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("insurtech-product-collection", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-insurtech-product-collection" */ "components/flexComponents/InsurtechProductCollection/InsurtechProductCollection"
        ) as ImportReactComponent
    ),
  })
  .set("best-time-to-go", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-best-time-to-go" */ "components/flexComponents/BestTimeToGo/BestTimeToGo"
        ) as ImportReactComponent
    ),
  })
  .set("best-time-to-go-information", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-best-time-to-go-information" */ "components/flexComponents/BestTimeToGoInformation/BestTimeToGoInformation"
        ) as ImportReactComponent
    ),
  })
  .set("price-insights", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-price-insights" */ "components/flexComponents/PriceInsights/PriceInsights"
        ) as ImportReactComponent
    ),
  })
  .set("destination-neighborhood", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-destination-neighborhood" */ "src/components/flexComponents/DestinationNeighborhood/DestinationNeighborhood"
        ) as ImportReactComponent
    ),
  })
  .set("discovery-product-listing-map", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-discovery-product-listing-map" */ "src/components/flexComponents/DiscoveryProductListingMap/DiscoveryProductListingMap"
        ) as ImportReactComponent
    ),
  })
  .set("travel-agent-rewards", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-travel-agent-rewards" */ "components/flexComponents/TravelAgentRewards/TravelAgentRewards"
        ) as ImportReactComponent
    ),
  })
  .set("property-types-carousel", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-property-types-carousel" */ "src/components/flexComponents/PropertyTypesCarousel/PropertyTypesCarousel"
        ) as ImportReactComponent
    ),
  })
  .set("reviews-carousel-component", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-reviews-carousel-component" */ "components/flexComponents/ReviewsCarouselComponent/ReviewsCarouselComponent"
        ) as ImportReactComponent
    ),
  })
  .set("click-to-claim-toast", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-click-to-claim-toast" */ "components/flexComponents/ClickToClaimToast/ClickToClaimToast"
        ) as ImportReactComponent
    ),
  })
  .set("mojo", {
    component: codeSplit(
      () =>
        import(/* webpackChunkName: "blossom-mojo" */ "src/components/flexComponents/Mojo/Mojo") as ImportReactComponent
    ),
  })
  .set("region_mojo-playground", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_mojo-playground" */ "src/components/flexComponents/MojoPlayground/MojoPlayground"
        ) as ImportReactComponent
    ),
  })
  .set("voice-of-the-customer", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-voice-of-the-customer" */ "components/flexComponents/VoiceOfTheCustomer/VoiceOfTheCustomer"
        ) as ImportReactComponent
    ),
  })
  .set("answering-traveller-questions", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-answering-traveller-questions" */ "components/flexComponents/AnsweringTravellerQuestions/AnsweringTravellerQuestions"
        ) as ImportReactComponent
    ),
  })
  .set("virtual-agent-control", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-virtual-agent-control" */ "components/flexComponents/VirtualAgentControl/VirtualAgentControl"
        ) as ImportReactComponent
    ),
  })
  .set("landing-alternative-airports", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-landing-alternative-airports" */ "components/flexComponents/LandingAlternativeAirports/LandingAlternativeAirports"
        ) as ImportReactComponent
    ),
  })
  .set("landing-flight-deals", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-landing-flight-deals" */ "components/flexComponents/LandingFlightDeals/LandingFlightDeals"
        ) as ImportReactComponent
    ),
  })
  .set("featured-suppliers", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-featured-suppliers" */ "components/flexComponents/FeaturedSuppliers/FeaturedSuppliers"
        ) as ImportReactComponent
    ),
  })
  .set("activity-recommendation", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-activity-recommendation" */ "components/flexComponents/ActivityRecommendation/ActivityRecommendation"
        ) as ImportReactComponent
    ),
  })
  .set("region_marquee-ad-region", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_marquee-ad-region" */ "components/flexComponents/MarqueeAdRegion/MarqueeAdRegion"
        ) as ImportReactComponent
    ),
  })
  .set("coupons-terms-and-conditions-content", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-coupons-terms-and-conditions-content" */ "components/flexComponents/CouponsTermsAndConditionsContent/CouponsTermsAndConditionsContent"
        ) as ImportReactComponent
    ),
  })
  .set("geo-affinity-link-piles", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-geo-affinity-link-piles" */ "components/flexComponents/GeoAffinityLinkPiles/GeoAffinityLinkPiles"
        ) as ImportReactComponent
    ),
  })
  .set("random-access-one", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-random-access-one" */ "components/flexComponents/RandomAccessOneWrapper/RandomAccessOneWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("credit-card-awareness-placement", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-credit-card-awareness-placement" */ "components/flexComponents/CreditCardAwarenessPlacement/CreditCardAwarenessPlacement"
        ) as ImportReactComponent
    ),
  })
  .set("region_hidden", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-region_hidden" */ "components/flexComponents/HiddenRegion/HiddenRegion"
        ) as ImportReactComponent
    ),
  })
  .set("discovery-landing-offers", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-discovery-landing-offers" */ "components/flexComponents/DiscoveryLandingOffers/DiscoveryLandingOffers"
        ) as ImportReactComponent
    ),
  })
  .set("loyalty-signup-wrapper", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-loyalty-signup-wrapper" */ "src/components/flexComponents/LoyaltySignupWrapper/LoyaltySignupWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("finish-your-booking", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-finish-your-booking" */ "components/flexComponents/FinishYourBooking/FinishYourBooking"
        ) as ImportReactComponent
    ),
  })
  .set("similar-destinations-properties", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-similar-destinations-properties" */ "components/flexComponents/SimilarDestinationsProperties/SimilarDestinationsProperties"
        ) as ImportReactComponent
    ),
  })
  .set("managed-banner-wrapper", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-managed-banner-wrapper" */ "components/flexComponents/ManagedBannerWrapper/ManagedBannerWrapper"
        ) as ImportReactComponent
    ),
  })
  .set("partner-messaging-banner", {
    component: codeSplit(
      () =>
        import(
          /* webpackChunkName: "blossom-partner-messaging-banner" */ "components/flexComponents/PartnerMessagingBanner/PartnerMessagingBanner"
        ) as ImportReactComponent
    ),
  });
